<template>
  <div class="box" ref="box">
    <h1 class="h1_title">在线报名</h1>
    <div class="nav">
      <ul>
        <li v-for="item in fromName.list" :key="item.key"><label :for="item.key">{{item.title}}</label><input :type="item.key == 'mobile_phone'?'number':'text'" :id="item.key" :placeholder="item.palce" v-model="from[item.key]"></li>
        <li><button @click="submit">提&nbsp;&nbsp;&nbsp;&nbsp;交</button> <button>取&nbsp;&nbsp;&nbsp;&nbsp;消</button></li>
      </ul>
    </div>
  </div>
  <signUp class="signUp"></signUp>
</template>


<script setup>
import signUp from '@/components/phonesignUp.vue'
import Pagination from '@/components/Pagination.vue'
import {ref,onMounted, reactive, nextTick} from 'vue'
import { useRouter,useRoute, } from 'vue-router'
import base64_ from '@/hooks/useBase64text.js'
import * as http from '@/api/API.js'
import { ElLoading,ElNotification } from 'element-plus'
const baseUrl = process.env.VUE_APP_BASE_URL
const router = useRouter()
const route = useRoute()
let routeData = JSON.parse(base64_.decode(route.params.obj))
let fromName = reactive({
  list:[
  {palce:'请输入您的姓名',key:'username',title:'姓名'},
  {palce:'请输入您的单位名称',key:'company',title:'单位'},
  {palce:'请输入您的职位',key:'position',title:'职务'},
  {palce:'请输入您的手机号',key:'mobile_phone',title:'手机'},
  {palce:'请输入您想报名的课程名称',key:'intended_course',title:'意向课程'},
  ]
})
let from = reactive({
  username:'',
  company:'',
  position:'',
  mobile_phone:'',
  intended_course:'',
})

let datas = ref('')
let box = ref("box")
let getBgxmlist = async (obj) => {
  
    setTimeout(() => {
    box.value.style.transform = 'translateY(0)'
    box.value.style.opacity = '1'
    },500)
}
onMounted(() => {
  getBgxmlist()
})
// 提交表单
const submit = () => {


// for(let key in from){
//   if(from[key]){
//   //  console.log(key+':'+from[key]);
//   }else{
//      console.log('请输入：'+key);
//     ElNotification({
//     title: 'Info',
//     message: '所有选线都是必填哦~~',
//     type: 'info',
//     zIndex:99999999,
//   })
//   }
// }


if(!from.username) {
  rules('姓名')
  return
}
if(!from.company) {
  rules('单位名称')
  return
}
if(!from.position) {
  rules('职位')
  return
}
if(!isMobilePhone(from.mobile_phone)) {
  rules('手机号')
  return
}
if(!from.intended_course) {
  rules('课程名称')
  return
}

  const loadingInstance = ElLoading.service({
    text:"信息提交中，请稍等...",
    background:'rgba(0, 0, 0, 0.8)',
    // spinner:`spinner`
  })
http.postHttp("/api/online_reg/add",from).then(res => {
  if(res.data.code == 200){
    ElNotification({
    title: 'Success',
    message: res.data.msg,
    type: 'success',
    zIndex:99999999,
  })
  loadingInstance.close()
  }
})
}

// 验证手机号正则
const isMobilePhone = value => {
  var reg =/^1[3456789]\d{9}$/ig;
  return reg.test(value)
} 
const rules = (sty) => {
  ElNotification({
    title: 'Info',
    message: `请输入正确的${sty}~~`,
    type: 'info',
    zIndex:999999999999,
  })
}
</script>


<style lang="less" scoped>
@maxw:128rem;
.box{
  margin: 0 20/@maxw;
  transform: translateY(800px);
  opacity: 0;
  transition: ease .8s;
  width: 100%;
   .h1_title{
    box-sizing: border-box;
    font-size: 39/@maxw;
    font-family: fantBold;
    font-weight: 300;
    color: #174994;
    border-bottom:2/@maxw solid #174994;
    margin: 0;
    padding: 0 0 30/@maxw 0;
  }
  .nav{
    box-sizing: border-box;
    // margin-top: 60/@maxw;
    margin-top: 30/@maxw;
    width: 100%;
    height: 630/@maxw;
    background-image: url("../../../assets/zaixianbaoming.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 68/@maxw 80/@maxw 80/@maxw 66/@maxw;
    ul{
      margin: 0;
      padding: 0;
      li{
        height: 48/@maxw;
        margin-bottom: 32/@maxw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        >label{
          color: #5F5F5F;
          font-size: 27/@maxw;
        }
        >input{
          width: 800/@maxw;
          height: 100%;
          border-radius: 10/@maxw;
          border: 1px solid #707070;
          font-size: 27/@maxw;
          padding-left: 23/@maxw;
          color: #646464;
          transition: ease .3s;
        }
        input::placeholder {  color: #D0D0D0; }
      }
      >li:last-child{
          margin-top: 64/@maxw;
          display: flex;
          justify-content: center;
          button{
            width: 220/@maxw;
            height: 56/@maxw;
            border-radius: 10/@maxw;
            font-size: 27/@maxw;
            cursor: pointer;
            transition: ease .3s;
          }
          >button:first-child{
            margin-right: 35/@maxw;
            background: linear-gradient(to right,#174994,#387DE2);
            color: #fff;
            border:none;
          }
          >button:last-child{
            background-color: #F7F7F7;
            border:1px solid #707070;
            color: #5A5A5A;
          }
          >button:hover{ 
            transform: translate(5px,6px);
          }
      }
    }
  }
  }
@media screen and (max-width:750px) {
@maxw_:750/100vw;
.signUp{
  display: none;
}
 .box{
   .h1_title{
    font-size: 39/@maxw_;
    font-family: fantBold;
    border-bottom:2/@maxw_ solid #174994;
    padding: 0 0 30/@maxw_ 0;
  }
  .nav{
    margin-top: 30/@maxw_;
    width: 100%;
    height: 630/@maxw_;
    padding: 68/@maxw_ 50/@maxw_ 80/@maxw_ 66/@maxw_;
    ul{
      li{
        height: 48/@maxw_;
        margin-bottom: 32/@maxw_;
        >label{
          font-size: 27/@maxw_;
          width: 250/@maxw_;
        }
        >input{
          width: 800/@maxw_;
          border-radius: 10/@maxw_;
          font-size: 27/@maxw_;
          padding-left: 23/@maxw_;
        }
      }
      >li:last-child{
          margin-top: 64/@maxw_;
          button{
            width: 220/@maxw_;
            height: 56/@maxw_;
            border-radius: 10/@maxw_;
            font-size: 27/@maxw_;
          }
          >button:first-child{
            margin-right: 35/@maxw_;
          }
      }
    }
  }
  }
}
</style>

